import React from "react";
import { styled, Table, TableBody, TableCell, TableRow } from "@mui/material";

const StyledTable = styled(Table)`
  max-height: 10px !important;
  margin-top: 10px;
  margin-bottom: 20px;
`;
const StyledTableRow = styled(TableRow)`
  padding: 0;
  height: 35px;
  &:nth-of-type(odd) {
    background-color: #dbe4e6;
    max-height: 10px;
    color: #ffffff !important;
  }
  &:nth-of-type(even) {
    background-color: #eff1f2;
    max-height: 10px;
    color: #ffffff !important;
  }
`;
const StyledTableCell = styled(TableCell)`
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 16px;
  font-family: ${({ theme }) => theme.fonts.family.default};
  border-bottom: none !important;
`;
const StyledTitleTableCell = styled(TableCell)`
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 16px;
  font-weight: bold;
  font-family: ${({ theme }) => theme.fonts.family.default};
  border-bottom: none !important;
  cursor: pointer;
  max-width: 100px;
  word-wrap: break-word;
`;

const TopAlerts = ({ data }) => {
  return (
    <>
      <StyledTable>
        <TableBody>
          <StyledTableRow>
            <StyledTitleTableCell>Cliente</StyledTitleTableCell>
            <StyledTitleTableCell>Roteiro</StyledTitleTableCell>
            <StyledTitleTableCell>
              Qtde. Roteiro com Atraso
            </StyledTitleTableCell>
            <StyledTitleTableCell>Qtde. Possível Erro</StyledTitleTableCell>
            <StyledTitleTableCell>Qtde. Roteiro Parado</StyledTitleTableCell>
            <StyledTitleTableCell>Qtde. Total</StyledTitleTableCell>
          </StyledTableRow>
          {data.map((item, index) => {
            return (
              <StyledTableRow key={index}>
                <StyledTableCell>{item.empresaAbreviado}</StyledTableCell>
                <StyledTableCell>{item.roteiro}</StyledTableCell>
                <StyledTableCell>
                  {item.quantidadeRoteiroAtraso}
                </StyledTableCell>
                <StyledTableCell>
                  {item.quantidadeFaltaMensagem}
                </StyledTableCell>
                <StyledTableCell>
                  {item.quantidadeRoteiroParado}
                </StyledTableCell>
                <StyledTableCell>{item.quantidadeTotal}</StyledTableCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </StyledTable>
    </>
  );
};

export default TopAlerts;
