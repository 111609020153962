import React from "react";
import { Button } from "../../components";
import { ControlledTextField } from "../../components/inputs";
import { Grid, InputAdornment, Link } from "@mui/material";
import { LockOutlined, PersonOutline, Visibility, VisibilityOff } from "@mui/icons-material";
import { styled } from "@mui/system";

const StyledButton = styled(Button)`
    margin-top: 20px;
    background: linear-gradient(92.97deg, #2f85c2 44.56%, #8ebecd 96.62%);
    height: 40px;
    width: 219px;
    border-radius: 100px;
    padding: 10px 24px 10px 24px;
`;

const StyledLink = styled(Link)`
    font-size: 14px;
    cursor: pointer;
    color: #3f484a;
    font-family: ${({ theme }) => theme.fonts.family.form};
`;

const StyledFormGrid = styled(Grid)`
    margin-top: 15px;
    margin-bottom: 15px;
`;

const StyledButtonGrid = styled(Grid)`
    text-align: center;
    align-items: center;
`;

const StyledLinkGrid = styled(Grid)`
    text-align: right;
    align-items: right;
    margin-bottom: 10px;
    margin-top: -10px;
`;

const StyledControlledTextField = styled(ControlledTextField)`
    margin-bottom: 5px;
    margin-top: 5px;
    width: 100%;
    flex: 1 !important;

    @media (max-width: 900px) {
        margin-bottom: 5px;
        margin-top: 5px;
    }

    & .MuiInputLabel-root {
        background-color: #d4ecf5;
        color: #3f484a !important;
        font-family: ${({ theme }) => theme.fonts.family.form};
        font-weight: 700;
        padding: 8px 0;
    }

    & .MuiFormHelperText-root.Mui-error {
        color: #d32f2f !important;
    }

    & .MuiOutlinedInput-root fieldset {
        border-color: #3f484a;
    }

    &:hover .MuiOutlinedInput-root fieldset {
        border-color: #3f484a;
    }

    & .Mui-focused .MuiOutlinedInput-root fieldset {
        border: 1.5px solid #3f484a;
    }

    & .Mui-error .MuiOutlinedInput-root fieldset {
        border: 1.5px solid #d32f2f;
    }
`;

const LoginForm = ({ loading, formProps, handleSubmit, handleClickForgotPassword }) => {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <form
      className="d-flex flex-column"
      onSubmit={formProps.handleSubmit(handleSubmit)}
    >
      <StyledFormGrid item xs={12} md={12}>
        <StyledControlledTextField
          label="Usuário"
          name="user"
          formProps={formProps}
          fullWidth
          margin="none"
          InputProps={{
            style: {
              textTransform: "lowercase",
              fontFamily: "Noto Sans",
              letterSpacing: "1px",
            },
            startAdornment: (
              <InputAdornment position="start">
                <PersonOutline style={{ color: "#3f484a" }} />
              </InputAdornment>
            ),
          }}
        />
      </StyledFormGrid>
      <StyledFormGrid item xs={12} md={12}>
        <StyledControlledTextField
          label="Senha"
          type={showPassword ? "text" : "password"}
          name="password"
          formProps={formProps}
          margin="none"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LockOutlined style={{ color: "#3f484a" }} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                {showPassword ? (
                  <VisibilityOff
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    style={{ color: "#3f484a" }}
                  />
                ) : (
                  <Visibility
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    style={{ color: "#3f484a" }}
                  />
                )}
              </InputAdornment>
            ),
          }}
        />
      </StyledFormGrid>

      <StyledLinkGrid item xs={12} md={12}>
        <StyledLink underline="hover" onClick={handleClickForgotPassword}>
          Esqueci minha senha
        </StyledLink>
      </StyledLinkGrid>
      <StyledButtonGrid item xs={12} md={12}>
        <StyledButton loading={loading} type="submit">
          ENTRAR
        </StyledButton>
      </StyledButtonGrid>
    </form>
  );
};

export default LoginForm;
