import { useForm } from "react-hook-form";
import FormEditarClientePage from "./FormEditarClientePage";
import { useSelector } from "react-redux";
import validators from "../../../utils/validators";
import { useDispatch } from "react-redux";
import {
  actions as routeActions,
  types as routes,
} from "../../../reducers/rotas.actions";
import { useCallback, useEffect } from "react";
import {
  getChaveApi,
  getCliente,
  getMatrizes,
} from "../../../selectors/cadastro/clientes.selectors";
import {
  removeNonDigitsFromString,
  validateLatLng,
} from "../../../utils/basic";
import { actions } from "../../../reducers/cadastro/clientes.actions";
import formatters from "../../../utils/formatters";
import { toast } from "react-toastify";

const FormEditarClientePageConnected = () => {
  const { loading, data, deletingGroup } = useSelector(getCliente);
  const { submitting, data: chaveApi } = useSelector(getChaveApi);
  const { data: dataMatrizes, loading: loadingMatrizes } = useSelector(
    getMatrizes
  );
  const dispatch = useDispatch();

  const rules = {
    nome: validators.string({ required: true }),
    situacao: validators.string({ required: true }),
    reference: validators.string({ required: true }),
    cep: validators.string({ required: true, length: 9 }),
    logradouro: validators.string({ required: true }),
    numeroLogradouro: validators.string({ required: true }),
    complemento: validators.string({ required: false }),
    bairro: validators.string({ required: true }),
    municipio: validators.string({ required: true }),
    uf: validators.string({ required: true }),
    cnpj: validators.cnpj({ required: true }),
    tipo: validators.string({ required: true }),
    idMatriz: validators.number({
      custom: {
        requiredIfOther: (value) => {
          const matriz = formProps.getValues("tipo");

          if (matriz === "F") {
            return !!value || "Campo Obrigatório";
          } else {
            return true;
          }
        },
      },
    }),
    pontosRegiao: validators.number({
      custom: {
        requiredIfOther: (value) => {
          if (!!value && value < 1) {
            return !!value || "Valor mínimo (1)";
          } else {
            return true;
          }
        },
      },
    }),
    raioPrecisao: validators.number({
      custom: {
        requiredIfOther: (value) => {
          if (!!value && value < 1) {
            return !!value || "Valor mínimo (1)";
          } else {
            return true;
          }
        },
      },
    }),
    precisao: validators.number({
      custom: {
        requiredIfOther: (value) => {
          if (!!value && value < 1) {
            return !!value || "Valor mínimo (1)";
          } else {
            return true;
          }
        },
      },
    }),
    latitude: validators.string({
      custom: {
        requiredIfOther: (value) => {
          if (!value) {
            return "Campo obrigatório";
          }
          if (isNaN(value * 1)) {
            return "Latitude inválida";
          }
          if (!validateLatLng(value)) {
            return "Latitude inválida";
          } else {
            return true;
          }
        },
      },
    }),
    longitude: validators.string({
      custom: {
        requiredIfOther: (value) => {
          if (!value) {
            return !!value || "Campo obrigatório";
          }
          if (isNaN(value * 1)) {
            return "Longitude inválida";
          }
          if (!validateLatLng(value)) {
            return !!value || "Longitude inválida";
          } else {
            return true;
          }
        },
      },
    }),
  };

  useEffect(() => {
    if (data !== []) {
      formProps.setValue("bairro", data?.bairro ?? "");
      formProps.setValue("cep", formatters.strings.zipCode(data?.cep) ?? "");
      formProps.setValue("cnpj", data?.cnpj ?? "");
      formProps.setValue("complemento", data?.complemento ?? "");
      formProps.setValue(
        "dataHoraAtualizacao",
        formatters.dates.withHours(new Date(data?.dataHoraAtualizacao)) ?? ""
      );
      formProps.setValue(
        "dataHoraCriacao",
        formatters.dates.withHours(new Date(data?.dataHoraCriacao)) ?? ""
      );
      formProps.setValue("idMatriz", data?.idMatriz ?? "");
      formProps.setValue("logradouro", data?.logradouro ?? "");
      formProps.setValue("lote", data?.lote ?? "");
      formProps.setValue("municipio", data?.municipio ?? "");
      formProps.setValue("nome", data?.nome ?? "");
      formProps.setValue("nomeAbreviado", data?.nomeAbreviado ?? "");
      formProps.setValue("numeroLogradouro", data?.numeroLogradouro ?? "");
      formProps.setValue("pontosRegiao", data?.pontosRegiao ?? "");
      formProps.setValue("precisao", data?.precisao ?? "");
      formProps.setValue("raioPrecisao", data?.raioPrecisao ?? "");
      formProps.setValue(
        "roteiro",
        data?.roteiro === "S" ? true : false ?? false
      );
      formProps.setValue("situacao", data?.situacao ?? "");
      formProps.setValue("longitude", data?.longitude ?? "");
      formProps.setValue("latitude", data?.latitude ?? "");
      formProps.setValue("tipo", data?.tipo ?? "");
      formProps.setValue("uf", data?.uf ?? "");
      formProps.setValue("id", data?.id ?? "");
      formProps.setValue(
        "exibeMapaProUsuarioFinal",
        data?.exibeMapaProUsuarioFinal === "S" ? true : false ?? false
      );
      formProps.setValue(
        "exibeOpcaoPercursoOperacaoDiaria",
        data?.exibeOpcaoPercursoOperacaoDiaria === "S" ? true : false ?? false
      );
      formProps.setValue(
        "exibeOpcaoEfetivoOperacaoDiaria",
        data?.exibeOpcaoEfetivoOperacaoDiaria === "S" ? true : false ?? false
      );
    }
    // eslint-disable-next-line
  }, [data]);

  const initialValues = {
    bairro: "",
    cep: "",
    cnpj: "",
    complemento: "",
    dataHoraAtualizacao: "",
    dataHoraCriacao: "",
    idMatriz: "",
    logradouro: "",
    lote: "",
    municipio: "",
    nome: "",
    nomeAbreviado: "",
    numeroLogradouro: "",
    pontosRegiao: "",
    raioPrecisao: "",
    roteiro: true,
    situacao: "",
    tipo: "",
    uf: "",
    id: "",
    latitude: "",
    longitude: "",
    precisao: "",
    exibeMapaProUsuarioFinal: false,
    exibeOpcaoPercursoOperacaoDiaria: false,
    exibeOpcaoEfetivoOperacaoDiaria: false,
  };

  const formProps = {
    ...useForm({ defaultValues: initialValues }),
    rules,
  };

  const handleSubmit = useCallback(
    (values) => {
      const matriz = formProps.getValues("tipo");
      matriz === "M"
        ? (values.idMatriz = null)
        : (values.idMatriz = values.idMatriz.id);

      values.cnpj = removeNonDigitsFromString(values.cnpj);
      values.cep = removeNonDigitsFromString(values.cep);
      const roteiro = formProps.getValues("roteiro");
      const exibeMapaProUsuarioFinal = formProps.getValues(
        "exibeMapaProUsuarioFinal"
      );
      const exibeOpcaoPercursoOperacaoDiaria = formProps.getValues(
        "exibeOpcaoPercursoOperacaoDiaria"
      );
      const exibeOpcaoEfetivoOperacaoDiaria = formProps.getValues(
        "exibeOpcaoEfetivoOperacaoDiaria"
      );
      values.exibeMapaProUsuarioFinal = exibeMapaProUsuarioFinal ? "S" : "N";
      values.exibeOpcaoPercursoOperacaoDiaria = exibeOpcaoPercursoOperacaoDiaria
        ? "S"
        : "N";
      values.exibeOpcaoEfetivoOperacaoDiaria = exibeOpcaoEfetivoOperacaoDiaria
        ? "S"
        : "N";
      const feriado = formProps.getValues("feriado");
      values.roteiro = roteiro ? "S" : "N";
      values.feriado = feriado ? "S" : "N";

      dispatch(actions.editarCliente.request(values));
    },
    // eslint-disable-next-line
    [dispatch]
  );

  const handleClickVoltar = () => {
    dispatch(routeActions.redirectTo(routes.CADASTRO_CLIENTE));
  };

  const { setValue } = formProps;

  const successCallback = useCallback(
    ({ data }) => {
      setValue("logradouro", data?.logradouro || "", { shouldValidate: true });
      setValue("bairro", data?.bairro || "", { shouldValidate: true });
      setValue("uf", data?.uf || "", { shouldValidate: true });
      setValue("municipio", data?.localidade || "", { shouldValidate: true });
    },
    [setValue]
  );

  const handleZipCodeBlurred = (value) => {
    if (removeNonDigitsFromString(value)?.length === 8) {
      dispatch(
        actions.buscarCep.request({
          value,
          successCallback,
        })
      );
    }
  };

  const handleDeleteGrupo = (id) => {
    dispatch(
      actions.removerGrupo.request({
        id,
      })
    );
  };

  const handleCopyApiKey = useCallback(async (text) => {
    await window.navigator.clipboard.writeText(text);
    toast.info("Chave API copiada com sucesso");
  }, []);
  const handleGenerateApiKey = useCallback(() => {
    dispatch(
      actions.gerarChaveComunicacao.request({
        id: data.id,
      })
    );
  }, [data]);
  const handleDeleteApiKey = useCallback(() => {
    dispatch(
      actions.removerChaveComunicacao.request({
        id: data.id,
      })
    );
  }, [data]);

  return (
    <FormEditarClientePage
      handleGoBack={handleClickVoltar}
      loading={loading}
      formProps={formProps}
      handleSubmit={handleSubmit}
      handleZipCodeBlurred={handleZipCodeBlurred}
      dataMatrizes={dataMatrizes}
      loadingMatrizes={loadingMatrizes}
      handleDeleteGrupo={handleDeleteGrupo}
      deletingGroup={deletingGroup}
      data={data}
      submittingChaveApi={submitting}
      chaveApi={chaveApi}
      handleGenerateApiKey={handleGenerateApiKey}
      handleDeleteApiKey={handleDeleteApiKey}
      handleCopyApiKey={handleCopyApiKey}
    />
  );
};

export default FormEditarClientePageConnected;
