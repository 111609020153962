import { createSelector } from "reselect";

export const getClienteCadastro = (state) => state.clientes;

export const getClientes = createSelector(
  getClienteCadastro,
  (state) => state.clientes
);

export const getClientesLista = createSelector(
  getClienteCadastro,
  (state) => state.clientesLista
);

export const getCliente = createSelector(
  getClienteCadastro,
  (state) => state.cliente
);

export const getChaveApi = createSelector(
  getClienteCadastro,
  (state) => state.chaveApi
);

export const getMatrizes = createSelector(
  getClienteCadastro,
  (state) => state.matrizes
);

export const getClientesPage = createSelector(
  getClienteCadastro,
  (state) => state.clientes.page
);

export const getClientesFilter = createSelector(
  getClienteCadastro,
  (state) => state.clientes.filter
);
