import React from "react";
import {
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { DeleteSharp, MenuTwoTone, SaveAlt } from "@mui/icons-material";
import formatters from "../../../utils/formatters";
import { useState } from "react";
import PosicaoEditarDialog from "./PosicaoEditarDialog";
import { DeleteDialog } from "../../../components";

const StyledTable = styled(Table)`
  max-height: 10px !important;
  margin-top: 10px;
  margin-bottom: 20px;
`;

const StyledTableRow = styled(TableRow)`
  padding: 0;
  height: 35px;
  &:nth-of-type(odd) {
    background-color: #dbe4e6;
    max-height: 10px;
    color: #ffffff !important;
  }
  &:nth-of-type(even) {
    background-color: #eff1f2;
    max-height: 10px;
    color: #ffffff !important;
  }
`;
const StyledTableCell = styled(TableCell)`
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 16px;
  font-family: Noto Sans;
  border-bottom: none !important;
`;
const StyledTitleTableCell = styled(TableCell)`
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 16px;
  font-weight: bold;
  font-family: Noto Sans;
  border-bottom: none !important;
`;

const StyledButtonTableCell = styled(TableCell)`
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 16px;
  font-family: Noto Sans;
  border-bottom: none !important;
  justify-contend: flex-end;
`;

const TabelaEditarPosicao = ({
  dataPosicao,
  dataKml,
  handleDeleteFuncionamento,
  handleDeletePosicao,
  handleDeleteKml,
  handleBaixarArtefato,
}) => {
  const [open, setOpen] = useState(false);
  const [dialogList, setDialogList] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogKml, setOpenDialogKml] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [idToAct, setIdToAct] = useState("");
  const [idToActKml, setIdToActKml] = useState("");

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenDialog = (posicoes) => {
    setDialogList(posicoes);
    setOpen(true);
  };

  if (
    (dataPosicao?.length === 0 && dataKml?.length === 0) ||
    (!dataKml && !dataPosicao)
  ) {
    return <></>;
  }

  const handleDeleteKmlInside = (item) => {
    handleDeleteKml(item);
  };

  const handleDelete = (item) => {
    handleDeletePosicao(item);
  };

  return (
    <>
      <StyledTable>
        <TableBody>
          <StyledTableRow>
            <StyledTitleTableCell>Data</StyledTitleTableCell>
            <StyledTitleTableCell>Tipo</StyledTitleTableCell>
            <StyledTitleTableCell>Arquivo</StyledTitleTableCell>
            <StyledTitleTableCell></StyledTitleTableCell>
          </StyledTableRow>
          {dataPosicao &&
            dataPosicao.map((item, index) => {
              const handleBaixar = () => {
                handleBaixarArtefato(item);
              };
              return (
                <StyledTableRow key={index}>
                  <StyledTableCell>
                    {formatters.dates.withHours(new Date(item.dataHoraUpload))}
                  </StyledTableCell>
                  <StyledTableCell>Posição</StyledTableCell>
                  <StyledTableCell>{item.nome}</StyledTableCell>
                  <StyledButtonTableCell align="right">
                    <IconButton onClick={handleBaixar}>
                      <SaveAlt />
                    </IconButton>
                    <IconButton onClick={() => handleOpenDialog(item)}>
                      <MenuTwoTone />
                    </IconButton>
                    <IconButton
                      onClick={(e) => {
                        setDialogTitle(
                          `Tem certeza que gostaria de deletar o arquivo ${item.nome}?`
                        );
                        setIdToAct(item.id);
                        setOpenDialog(true);
                      }}
                    >
                      <DeleteSharp />
                    </IconButton>
                  </StyledButtonTableCell>
                </StyledTableRow>
              );
            })}
          {dataKml &&
            dataKml.map((item, index) => {
              const handleBaixar = () => {
                handleBaixarArtefato(item);
              };

              return (
                <StyledTableRow key={index}>
                  <StyledTableCell>
                    {formatters.dates.withHours(new Date(item.dataHoraUpload))}
                  </StyledTableCell>
                  <StyledTableCell>Kml</StyledTableCell>
                  <StyledTableCell>{item.nome}</StyledTableCell>
                  <StyledButtonTableCell align="right">
                    <IconButton onClick={handleBaixar}>
                      <SaveAlt />
                    </IconButton>
                    <IconButton
                      onClick={(e) => {
                        setDialogTitle(
                          `Tem certeza que gostaria de deletar o arquivo ${item.nome}?`
                        );
                        setIdToActKml(item.id);
                        setOpenDialogKml(true);
                      }}
                    >
                      <DeleteSharp />
                    </IconButton>
                  </StyledButtonTableCell>
                </StyledTableRow>
              );
            })}
        </TableBody>
        <DeleteDialog
          title={dialogTitle}
          open={openDialogKml}
          setOpen={setOpenDialogKml}
          deleteFunction={handleDeleteKmlInside}
          id={idToActKml}
        />
        <DeleteDialog
          title={dialogTitle}
          open={openDialog}
          setOpen={setOpenDialog}
          deleteFunction={handleDelete}
          id={idToAct}
        />
      </StyledTable>
      <PosicaoEditarDialog
        open={open}
        handleClose={handleClose}
        posicoes={dialogList}
      />
    </>
  );
};

export default TabelaEditarPosicao;
