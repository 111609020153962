import React from "react";
import { useSelector } from "react-redux";
import queryString from "query-string";
import { connectRoutes, NOT_FOUND } from "redux-first-router";
import restoreScroll from "redux-first-router-restore-scroll";
import { getCurrentRoute } from "./selectors/routes.selectors";
import { getPerfil, isUsuarioLogado } from "./selectors/usuario.selectors";
import {
  types as routes,
  actions as routeActions,
} from "./reducers/rotas.actions";
import * as pages from "./business/pages";
import { toast } from "react-toastify";
import jwtDecode from "jwt-decode";
import { getJwt } from "./utils/localStorage";
import { MapOsPage } from "./business/pages";

const routePages = {
  [routes.LOGIN]: {
    component: pages.LoginPage,
  },
  [NOT_FOUND]: {
    component: pages.LoginPage,
  },
  [routes.ESQUECI_SENHA]: {
    component: pages.EsqueciSenhaPage,
  },
  [routes.TROCAR_SENHA]: {
    component: pages.TrocarSenhaPage,
  },
  [routes.HOME_PAGE]: {
    component: pages.MapaExecucaoPage,
    restricted: (perfil) => perfil !== "E",
  },
  [routes.MAPA]: {
    component: pages.MapPage,
    restricted: (perfil) => {
      if (perfil === "E") {
        const { permissions = [] } = jwtDecode(getJwt());

        return !!permissions.find((a) => a === "MAPA_EU");
      }
      return true;
    },
  },
  [routes.MAPA_OS]: {
    component: pages.MapOsPage,
    restricted: (perfil) => {
      if (perfil === "E") {
        const { permissions = [] } = jwtDecode(getJwt());

        return !!permissions.find((a) => a === "MAPA_EU");
      }
      return true;
    },
  },
  [routes.OPERACAO_DIARIA]: { component: pages.MapaDiarioPage, restricted: [] },
  [routes.CADASTRO_USUARIO]: {
    component: pages.UsuariosPage,
    restricted: ["A"],
  },
  [routes.EDITAR_USUARIO]: {
    component: pages.EditarUsuariosPage,
    restricted: ["A"],
  },
  [routes.CRIAR_USUARIO]: {
    component: pages.CriarUsuarioPage,
    restricted: ["A"],
  },
  [routes.CADASTRO_DISPOSITIVO]: {
    component: pages.DispositivosPage,
    restricted: ["A"],
  },
  [routes.EDITAR_DISPOSITIVO]: {
    component: pages.EditarDispositivoPage,
    restricted: ["A"],
  },
  [routes.CRIAR_DISPOSITIVO]: {
    component: pages.CriarDispositivoPage,
    restricted: ["A"],
  },
  [routes.CADASTRO_CLIENTE]: {
    component: pages.ClientesPage,
    restricted: ["A"],
  },
  [routes.EDITAR_CLIENTE]: {
    component: pages.EditarClientePage,
    restricted: ["A"],
  },
  [routes.VIEW_CLIENTE]: {
    component: pages.ClienteViewPage,
    restricted: ["A"],
  },
  [routes.CRIAR_CLIENTE]: {
    component: pages.CriarClientePage,
    restricted: ["A"],
  },
  [routes.CADASTRO_INTEGRACAO]: {
    component: pages.IntegracoesPage,
    restricted: ["A"],
  },
  [routes.EDITAR_INTEGRACAO]: {
    component: pages.EditarIntegracaoPage,
    restricted: ["A"],
  },
  [routes.CRIAR_INTEGRACAO]: {
    component: pages.CriarIntegracaoPage,
    restricted: ["A"],
  },
  [routes.CADASTRO_ROTEIRO]: {
    component: pages.RoteirosPage,
    restricted: ["A"],
  },
  [routes.EDITAR_ROTEIRO]: {
    component: pages.EditarRoteiroPage,
    restricted: ["A"],
  },
  [routes.CRIAR_ROTEIRO]: {
    component: pages.CriarRoteiroPage,
    restricted: ["A"],
  },
  [routes.CARGA_ROTEIRO]: {
    component: pages.CargaRoteiroPage,
    restricted: ["A"],
  },
  [routes.GERACAO_OS]: {
    component: pages.GeracaoOsPage,
    restricted: ["A"],
  },
  [routes.CARGA_ARQUIVO]: {
    component: pages.CargaArquivo,
    restricted: ["A"],
  },
  [routes.CADASTRO_FERIADO]: {
    component: pages.FeriadosPage,
    restricted: ["A"],
  },
  [routes.EDITAR_FERIADO]: {
    component: pages.EditarFeriadoPage,
    restricted: ["A"],
  },
  [routes.CRIAR_FERIADO]: {
    component: pages.CriarFeriadoPage,
    restricted: ["A"],
  },
  [routes.REPROCESSAMENTO_OS]: {
    component: pages.ReprocessamentoOsPage,
    restricted: ["A"],
  },
  [routes.CADASTRO_MAPA_MENSAL]: {
    component: pages.MapasMensaisPage,
    restricted: ["A"],
  },
  [routes.EDITAR_MAPA_MENSAL]: {
    component: pages.EditarMapaMensalPage,
    restricted: ["A"],
  },
  [routes.CRIAR_MAPA_MENSAL]: {
    component: pages.CriarMapaMensalPage,
    restricted: ["A"],
  },
  [routes.CARGA_TROCAR_DISPOSITIVO]: {
    component: pages.CargaTrocarDispositivoPage,
    restricted: ["A"],
  },
  [routes.CARGA_DISPOSITIVO]: {
    component: pages.CargaDispositivoPage,
    restricted: ["A"],
  },
  [routes.BATERIAS]: {
    component: pages.BateriasPage,
    restricted: ["A"],
  },
  [routes.ERRO_OS]: {
    component: pages.RoteiroInconsistenciaPage,
    restricted: ["A"],
  },
  [routes.CADASTRO_ALERTA]: {
    component: pages.AlertasPage,
    restricted: ["A"],
  },
  [routes.EDITAR_ALERTA]: {
    component: pages.EditarAlertPage,
    restricted: ["A"],
  },
  [routes.CRIAR_ALERTA]: {
    component: pages.CriarAlertaPage,
    restricted: ["A"],
  },
  [routes.PAINEL_ALERTAS]: {
    component: pages.PainelAlertasPage,
    restricted: ["A"],
  },
  [routes.RELATORIO_PENDENTES]: {
    component: pages.RelatorioPontosPendentesPage,
    restricted: ["A"],
  },
};
export const routePaths = {
  [routes.LOGIN]: "/",
  [routes.LOGIN]: "/login",
  [routes.ESQUECI_SENHA]: "/esqueci-senha",
  [routes.GERACAO_OS]: "/configuracao/geracao-os",
  [routes.TROCAR_SENHA]: "/trocar-senha/:token",
  [routes.HOME_PAGE]: "/home",
  [routes.MAPA]: "/mapa",
  [routes.MAPA_OS]: "/mapa/os/:id",
  [routes.OPERACAO_DIARIA]: "/operacao-diaria",
  [routes.CADASTRO_USUARIO]: "/cadastro/usuario",
  [routes.EDITAR_USUARIO]: "/cadastro/editar/usuario/:id",
  [routes.CRIAR_USUARIO]: "/cadastro/criar/usuario",
  [routes.CADASTRO_DISPOSITIVO]: "/cadastro/dispositivo",
  [routes.EDITAR_DISPOSITIVO]: "/cadastro/editar/dispositivo/:id",
  [routes.CRIAR_DISPOSITIVO]: "/cadastro/criar/dispositivo",
  [routes.CADASTRO_CLIENTE]: "/cadastro/cliente",
  [routes.EDITAR_CLIENTE]: "/cadastro/editar/cliente/:id",
  [routes.VIEW_CLIENTE]: "/cadastro/mostrar/cliente/:id",
  [routes.CRIAR_CLIENTE]: "/cadastro/criar/cliente",
  [routes.CADASTRO_INTEGRACAO]: "/cadastro/integracao/:id",
  [routes.EDITAR_INTEGRACAO]: "/cadastro/editar/integracao/:id",
  [routes.CRIAR_INTEGRACAO]: "/cadastro/criar/integracao",
  [routes.CADASTRO_ROTEIRO]: "/cadastro/roteiro/:id",
  [routes.EDITAR_ROTEIRO]: "/cadastro/editar/roteiro/:id",
  [routes.CRIAR_ROTEIRO]: "/cadastro/criar/roteiro/:id",
  [routes.CARGA_ROTEIRO]: "/carga/roteiro",
  [routes.CARGA_ARQUIVO]: "/carga/arquivo",
  [routes.CADASTRO_FERIADO]: "/cadastro/feriado/:id",
  [routes.EDITAR_FERIADO]: "/cadastro/editar/feriado/:id",
  [routes.CRIAR_FERIADO]: "/cadastro/criar/feriado",
  [routes.REPROCESSAMENTO_OS]: "/configuracao/reprocessamento-os",
  [routes.CADASTRO_MAPA_MENSAL]: "/cadastro/mapa-mensal/:id",
  [routes.EDITAR_MAPA_MENSAL]: "/cadastro/editar/mapa-mensal/:id",
  [routes.CRIAR_MAPA_MENSAL]: "/cadastro/criar/mapa-mensal",
  [routes.CARGA_TROCAR_DISPOSITIVO]: "/carga/trocar-dispositivo",
  [routes.CARGA_DISPOSITIVO]: "/carga/dispositivo",
  [routes.BATERIAS]: "/baterias",
  [routes.ERRO_OS]: "/inconsistencias-geracao-os",
  [routes.CADASTRO_ALERTA]: "/cadastro/alerta/:id",
  [routes.EDITAR_ALERTA]: "/cadastro/editar/alerta/:id",
  [routes.CRIAR_ALERTA]: "/cadastro/criar/alerta/:idCliente",
  [routes.PAINEL_ALERTAS]: "/painel-alerta",
  [routes.RELATORIO_PENDENTES]: "/relatorios/pontos-pendentes",
};

const { reducer, middleware, enhancer } = connectRoutes(routePaths, {
  querySerializer: queryString,
  restoreScroll: restoreScroll(),
  notFoundPath: "/erro",
  onBeforeChange: (dispatch, getState, { action }) => {
    const route = routePages[action.type] ?? routePages[NOT_FOUND];
    if (route.restricted !== undefined) {
      const state = getState();
      if (!isUsuarioLogado(state)) {
        dispatch(routeActions.rejectTo(routes.LOGIN));
      } else {
        const perfil = getPerfil(state);
        if (!canAccessRestrictedRoute(route, perfil)) {
          toast.error("Acesso Negado");
          dispatch(routeActions.rejectTo(routes.HOME_PAGE));
        }
      }
    }
  },
});

export { reducer, middleware, enhancer };

const Container = () => {
  const routeCode = useSelector(getCurrentRoute);
  const route = routePages[routeCode] ?? routePages[NOT_FOUND];
  const Component = route.component;

  return <Component />;
};

const canAccessRestrictedRoute = (route, perfil) => {
  if (!perfil) {
    return false;
  }
  if (route.restricted === true) {
    return true;
  }
  if (typeof route.restricted === "function") {
    return route.restricted(perfil);
  }
  if (Array.isArray(route.restricted)) {
    if (
      route.restricted.length === 0 ||
      route.restricted.indexOf(perfil) > -1
    ) {
      return true;
    }
  }
  return false;
};

export default Container;
