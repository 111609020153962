import {
  createAsyncAction,
  createSyncAction,
} from "../../utils/actionCreators";

export const types = {
  OBTER_CLIENTES: "@@clientes/OBTER_CLIENTES",
  MOSTRAR_CLIENTE: "@@clientes/MOSTRAR_CLIENTE",
  EDITAR_CLIENTE: "@@clientes/EDITAR_CLIENTE",
  CRIAR_CLIENTE: "@@clientes/CRIAR_CLIENTE",
  DELETAR_CLIENTE: "@@clientes/DELETAR_CLIENTE",
  REATIVAR_CLIENTE: "@@clientes/REATIVAR_CLIENTE",
  REMOVER_FILTRO_CLIENTE: "@@clientes/REMOVER_FILTRO_CLIENTE",
  REMOVER_PAGINA_CLIENTE: "@@clientes/REMOVER_PAGINA_CLIENTE",
  BUSCAR_CEP: "@@clientes/BUSCAR_CEP",
  OBTER_MATRIZES: "@@clientes/OBTER_MATRIZES",
  OBTER_CLIENTES_SEM_FILTRO: "@@clientes/OBTER_CLIENTES_SEM_FILTRO",
  REMOVER_GRUPO: "@@clientes/REMOVER_GRUPO",
  GERAR_CHAVE_COMUNICACAO: "@@clientes/GERAR_CHAVE_COMUNICACAO",
  REMOVER_CHAVE_COMUNICACAO: "@@clientes/REMOVER_CHAVE_COMUNICACAO",
};

export const actions = {
  obterClientes: createAsyncAction(types.OBTER_CLIENTES),
  mostrarCliente: createAsyncAction(types.MOSTRAR_CLIENTE),
  editarCliente: createAsyncAction(types.EDITAR_CLIENTE),
  criarCliente: createAsyncAction(types.CRIAR_CLIENTE),
  deletarCliente: createAsyncAction(types.DELETAR_CLIENTE),
  reativarCliente: createAsyncAction(types.REATIVAR_CLIENTE),
  removerFiltroCliente: () => createSyncAction(types.REMOVER_FILTRO_CLIENTE),
  removerPaginaCliente: () => createSyncAction(types.REMOVER_PAGINA_CLIENTE),
  buscarCep: createAsyncAction(types.BUSCAR_CEP),
  obterMatrizes: createAsyncAction(types.OBTER_MATRIZES),
  obterClientesSemFiltro: createAsyncAction(types.OBTER_CLIENTES_SEM_FILTRO),
  removerGrupo: createAsyncAction(types.REMOVER_GRUPO),
  gerarChaveComunicacao: createAsyncAction(types.GERAR_CHAVE_COMUNICACAO),
  removerChaveComunicacao: createAsyncAction(types.REMOVER_CHAVE_COMUNICACAO),
};
