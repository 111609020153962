import { actions, types } from "./clientes.actions";
import { types as routes } from "../rotas.actions";

const initialState = {
  id: null,
  clientes: {
    loading: false,
    data: [],
    page: 1,
    filter: "",
  },
  clientesLista: {
    data: [],
    loading: false,
  },
  cliente: {
    loading: false,
    data: [],
    deletingGroup: false,
  },
  matrizes: {
    loadign: false,
    data: [],
  },
  chaveApi: {
    submitting: false,
    data: null,
  },
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.obterClientes.REQUEST:
    case actions.obterClientes.SUCCESS:
    case actions.obterClientes.FAILURE:
      return {
        ...state,
        clientes: {
          ...state.clientes,
          loading: action.type === actions.obterClientes.REQUEST,
          data:
            action.type === actions.obterClientes.SUCCESS
              ? action.payload.response.data
              : [],
          filter:
            action.type === actions.obterClientes.SUCCESS
              ? action.payload?.response?.data?.filtroAtual ?? ""
              : "",
          page:
            action.type === actions.obterClientes.SUCCESS
              ? action.payload?.response?.data?.paginaAtual ?? 1
              : 1,
        },
      };
    case actions.obterClientesSemFiltro.REQUEST:
    case actions.obterClientesSemFiltro.SUCCESS:
    case actions.obterClientesSemFiltro.FAILURE:
      return {
        ...state,
        clientesLista: {
          ...state.clientesLista,
          loading: action.type === actions.obterClientesSemFiltro.REQUEST,
          data:
            action.type === actions.obterClientesSemFiltro.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };
    case actions.obterMatrizes.REQUEST:
    case actions.obterMatrizes.SUCCESS:
    case actions.obterMatrizes.FAILURE:
      return {
        ...state,
        matrizes: {
          ...state.matrizes,
          loading: action.type === actions.obterMatrizes.REQUEST,
          data:
            action.type === actions.obterMatrizes.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };
    case actions.removerGrupo.REQUEST:
    case actions.removerGrupo.SUCCESS:
    case actions.removerGrupo.FAILURE:
      return {
        ...state,
        cliente: {
          ...state.matrizes,
          deletingGroup: action.type === actions.removerGrupo.REQUEST,
          data: {
            ...state.cliente.data,
            grupos:
              action.type === actions.removerGrupo.SUCCESS
                ? state.cliente.data.grupos.filter(
                    (g) => g.id !== action.payload.original.id
                  )
                : state.cliente.data.grupos,
          },
        },
      };
    case types.REMOVER_FILTRO_CLIENTE:
      return {
        ...state,
        clientes: {
          ...state.clientes,
          filter: "",
        },
      };
    case types.REMOVER_PAGINA_CLIENTE:
      return {
        ...state,
        clientes: {
          ...state.clientes,
          page: 1,
        },
      };

    case actions.editarCliente.REQUEST:
    case actions.editarCliente.SUCCESS:
    case actions.editarCliente.FAILURE:
      return {
        ...state,
        cliente: {
          ...state.cliente,
          loading: action.type === actions.editarCliente.REQUEST,
          data:
            action.type === actions.editarCliente.SUCCESS
              ? action.payload.response.data
              : state.cliente.data,
        },
      };

    case routes.EDITAR_CLIENTE:
      return {
        ...state,
        id: action.payload.id,
      };

    case actions.mostrarCliente.REQUEST:
    case actions.mostrarCliente.SUCCESS:
    case actions.mostrarCliente.FAILURE:
      return {
        ...state,
        cliente: {
          ...state.cliente,
          loading: action.type === actions.mostrarCliente.REQUEST,
          data:
            action.type === actions.mostrarCliente.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };
    case actions.criarCliente.REQUEST:
    case actions.criarCliente.SUCCESS:
    case actions.criarCliente.FAILURE:
      return {
        ...state,
        cliente: {
          ...state.cliente,
          loading: action.type === actions.criarCliente.REQUEST,
          data:
            action.type === actions.criarCliente.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };
    case actions.deletarCliente.REQUEST:
    case actions.deletarCliente.SUCCESS:
    case actions.deletarCliente.FAILURE:
      return {
        ...state,
        cliente: {
          ...state.cliente,
          loading: action.type === actions.deletarCliente.REQUEST,
        },
      };
    case actions.gerarChaveComunicacao.REQUEST:
    case actions.gerarChaveComunicacao.SUCCESS:
    case actions.gerarChaveComunicacao.FAILURE:
      return {
        ...state,
        chaveApi: {
          ...state.chaveApi,
          submitting: action.type === actions.gerarChaveComunicacao.REQUEST,
          data:
            action.type === actions.gerarChaveComunicacao.SUCCESS
              ? action.payload.response.data
              : null,
        },
        cliente: {
          ...state.cliente,
          data:
            action.type === actions.gerarChaveComunicacao.SUCCESS
              ? { ...state.cliente.data, ...action.payload.response.data }
              : state.cliente.data,
        },
      };
    case actions.removerChaveComunicacao.REQUEST:
    case actions.removerChaveComunicacao.SUCCESS:
    case actions.removerChaveComunicacao.FAILURE:
      return {
        ...state,
        chaveApi: {
          ...state.chaveApi,
          submitting: action.type === actions.removerChaveComunicacao.REQUEST,
        },
        cliente: {
          ...state.cliente,
          data:
            action.type === actions.removerChaveComunicacao.SUCCESS
              ? { ...state.cliente.data, chaveApi: null, segredoApi: null }
              : state.cliente.data,
        },
      };
    default:
      return state;
  }
};

export default reducer;
