import { createSelector } from "reselect";

export const getRelatorioPontosPendentes = (state) =>
  state["relatorio-pontos-pendentes"];

export const isProcessando = createSelector(
  getRelatorioPontosPendentes,
  (state) => state.processing
);

export const getDominios = createSelector(
  getRelatorioPontosPendentes,
  (state) => state.dominios
);

export const getDominioEmpresas = createSelector(
  getDominios,
  (state) => state.empresas
);
export const getDominioDispositivos = createSelector(
  getDominios,
  (state) => state.dispositivos
);
