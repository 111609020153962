import { Card, Grid, styled } from "@mui/material";
import React from "react";
import { GradientButton, Title } from "../../components/styled";
import BasePage from "../BasePage";
import {
  CustomControlledAutoComplete,
  DateTextField,
  IntegerTextField,
} from "../../components/inputs";
import ControlledCustomTextField from "../../components/inputs/ControlledCustomTextField";

const StyledCard = styled(Card)`
  background-color: #dbe4e6;
  height: auto;
  display: flex;
  border-radius: 20px;
  box-shadow: 0 0 !important;
  padding: 1.5rem;
  overflow: auto;
`;
const StyledCardTitle = styled("h1")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  font-size: 32px;
  font-weight: 300;
  line-height: 40px;
  letter-spacing: -0.25px;
  text-align: left;
  margin-top: 0;
  margin-bottom: 0.5rem;

  @media (max-width: 576px) {
    margin-left: 20px;
    margin-top: 10px;
    font-size: 43px;
  }
`;

function RelatorioPontosPendentesPage({
  showEmpresa,
  dominioEmpresas,
  dominioDispositivos,
  formProps,
  handleSubmit,
  processing,
}) {
  return (
    <BasePage isLoggedIn={true}>
      <Title>Relatório de Pontos Pendentes</Title>
      <form onSubmit={handleSubmit}>
        <StyledCard>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <StyledCardTitle>Filtros</StyledCardTitle>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <ControlledCustomTextField
                name={"dataInicio"}
                formProps={formProps}
                label={"Data Início"}
                InputProps={{
                  inputComponent: DateTextField,
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <ControlledCustomTextField
                name={"dataFim"}
                formProps={formProps}
                label={"Data Fim"}
                InputProps={{
                  inputComponent: DateTextField,
                }}
              />
            </Grid>
            {!showEmpresa && dominioEmpresas.length === 1 ? (
              <></>
            ) : (
              <Grid item xs={12} lg={6}>
                <CustomControlledAutoComplete
                  name={"empresa"}
                  formProps={formProps}
                  label={"Cliente"}
                  domain={dominioEmpresas}
                  labelField={"nome"}
                />
              </Grid>
            )}
            <Grid item xs={12} md={6} lg={4}>
              <ControlledCustomTextField
                name={"limite"}
                formProps={formProps}
                label={"% de Pontos com Pendência"}
                InputProps={{
                  inputComponent: IntegerTextField,
                }}
                inputProps={{ maxLength: 3 }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <ControlledCustomTextField
                name={"roteiro"}
                formProps={formProps}
                label={"Roteiro"}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              {dominioDispositivos && dominioDispositivos.length > 0 && (
                <CustomControlledAutoComplete
                  name={"dispositivo"}
                  formProps={formProps}
                  label={"Dispositivo"}
                  domain={dominioDispositivos}
                  labelField={"nome"}
                />
              )}
            </Grid>
            <Grid item container justifyContent={"center"} mt={1}>
              <Grid item xs={12} md={3}>
                <GradientButton loading={processing} type={"submit"}>
                  EXTRAIR
                </GradientButton>
              </Grid>
            </Grid>
          </Grid>
        </StyledCard>
      </form>
    </BasePage>
  );
}

export default RelatorioPontosPendentesPage;
