import BasePage from "../../BasePage";
import React, { useEffect, useState } from "react";
import {
  Card,
  Grid,
  IconButton,
  MenuItem,
  Skeleton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import {
  ControlledCustomTextField,
  CpfCnpjTextField,
  CustomControlledAutoComplete,
  ZipCodeTextField,
} from "../../../components/inputs";
import { Button, DeleteDialog } from "../../../components";
import { states } from "../../../utils/constants";
import CustomControlledSwitch from "../../../components/inputs/CustomControlledSwitch";
import { CopyAll, DeleteSharp } from "@mui/icons-material";
import { FlexContainer } from "../../../components/styled";
import formatters from "../../../utils/formatters";
import { transientOptions } from "../../../styles/theme";

const StyledCard = styled(Card)`
  background-color: #dbe4e6;
  height: auto;
  width: auto;
  margin: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  border-radius: 20px;
  box-shadow: 0 0 !important;
  padding: 25px;
`;
const StyledControlledCustomTextField = styled(ControlledCustomTextField)``;

const StyledAtualizarButton = styled(Button)`
  background: linear-gradient(92.97deg, #2f85c2 44.56%, #8ebecd 96.62%);
  height: 40px;
  width: 276px;
  border-radius: 100px;
  padding: 10px 24px 10px 24px;
  margin-top: 20px;
  margin-bottom: 20px;
  @media (max-width: 576px) {
    width: 100%;
  }
`;
const StyledVoltarButton = styled(Button)`
  margin-top: 20px;
  background: #ffffff !important;
  border: 1px solid #2f85c2;
  color: #2f85c2;
  height: 40px;
  width: 276px;
  border-radius: 100px;
  padding: 10px 24px 10px 24px;
  margin-bottom: auto;
  @media (max-width: 576px) {
    width: 100%;
  }
`;

const StyledGrid = styled(Grid)`
  padding: 5px;
`;

const StyledGridContainer = styled(Grid)``;

const ButtonContainer = styled("div")`
  display: flex;
  justify-content: center;
`;
const ButtonContainerLeft = styled("div")`
  display: flex;
  margin-left: 0;
  justify-content: center;
`;

const StyledSkeleton = styled(Skeleton)`
  width: 100%;
  height: 370px;
  margin-bottom: -65px;
  margin-top: -70px;
  border-radius: 20px;
`;

const StyledControlledSwitch = styled(CustomControlledSwitch)``;

const StyledForm = styled("form")`
  max-width: 100%;
`;

const StyledTitle = styled("h1")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  font-size: 57px;
  font-weight: 400;
  line-height: 64px;
  letter-spacing: -0.25px;
  text-align: left;
  margin-top: 25px;

  @media (max-width: 576px) {
    margin-left: 20px;
    margin-top: 10px;
    font-size: 43px;
  }
`;
const StyledCardTitle = styled("h1")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  font-size: 32px;
  font-weight: 300;
  line-height: 40px;
  letter-spacing: -0.25px;
  text-align: left;
  margin-top: 0;
  margin-bottom: 5px;

  @media (max-width: 576px) {
    margin-left: 20px;
    margin-top: 10px;
    font-size: 43px;
  }
`;
const StyledTable = styled(Table)`
  max-height: 10px !important;
  margin-top: 10px;
  margin-bottom: 20px;
`;
const StyledTableRow = styled(TableRow)`
  padding: 0;
  height: 35px;
  &:nth-of-type(odd) {
    background-color: #dbe4e6;
    max-height: 10px;
    color: #ffffff !important;
  }
  &:nth-of-type(even) {
    background-color: #eff1f2;
    max-height: 10px;
    color: #ffffff !important;
  }
`;
const StyledTableCell = styled(TableCell)`
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: ${({ theme }) => theme.fonts.size.default};
  font-family: ${({ theme }) => theme.fonts.family.default};
  border-bottom: none !important;
`;
const StyledTitleTableCell = styled(TableCell)`
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: ${({ theme }) => theme.fonts.size.default};
  font-weight: bold;
  font-family: ${({ theme }) => theme.fonts.family.default};
  border-bottom: none !important;
`;

const StyledButtonTableCell = styled(TableCell)`
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: ${({ theme }) => theme.fonts.size.default};
  font-family: ${({ theme }) => theme.fonts.family.default};
  border-bottom: none !important;
  justify-content: flex-end;
`;

const ThinText = styled("span")`
  font-weight: 300;
  font-size: ${({ theme }) => theme.fonts.size.small};
  display: inline-block;
  text-align: ${({ __align }) => __align ?? "center"};
`;
const SpacedThinText = styled(ThinText, transientOptions)`
  margin: ${({ __margin }) => __margin ?? "0"};
`;

const FormEditarClientePage = ({
  data,
  loading,
  formProps,
  handleSubmit,
  handleGoBack,
  handleZipCodeBlurred,
  handleDeleteGrupo,
  deletingGroup,
  dataMatrizes,
  loadingMatrizes,
  handleCopyApiKey,
  handleGenerateApiKey,
  handleDeleteApiKey,
  chaveApi,
  submittingChaveApi,
}) => {
  const [disableAutoComplete, setDisableAutoComplete] = useState(true);
  const [disableRoteiro, setDisableRoteiro] = useState(data);
  const [openDialog, setOpenDialog] = useState(false);
  const [openRemoveKey, setRemoveKey] = useState(false);
  const [openConfirmation, setConfirmation] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [idToAct, setIdToAct] = useState("");

  const handleDisableRoteiro = () => {
    formProps.setValue("roteiro", !disableRoteiro);
    setDisableRoteiro(!disableRoteiro);
    if (!disableRoteiro) {
      formProps.setValue("precisao", "");
    } else {
      formProps.setValue("raioPrecisao", "");
      formProps.setValue("pontosRegiao", "");
    }
  };

  useEffect(() => {
    formProps.setValue(
      "idMatriz",
      dataMatrizes.find((item) => item.id === data.idMatriz)
    );
    // eslint-disable-next-line
  }, [data, dataMatrizes]);

  useEffect(() => {
    setDisableRoteiro(data?.roteiro === "S");
    setDisableAutoComplete(data?.tipo === "M");
  }, [data]);

  if (loading) {
    return (
      <BasePage isLoggedIn={true}>
        <StyledTitle>Editar Cliente</StyledTitle>
        <StyledSkeleton />
      </BasePage>
    );
  }

  return (
    <BasePage>
      <StyledTitle>Editar Cliente</StyledTitle>
      <StyledForm onSubmit={formProps.handleSubmit(handleSubmit)}>
        <StyledCard>
          <StyledCardTitle>Dados Básicos</StyledCardTitle>
          <StyledGridContainer container>
            <StyledGrid item xs={12} sm={12} md={3}>
              <StyledControlledCustomTextField
                formProps={formProps}
                fullWidth
                name="cnpj"
                label="CNPJ"
                type="cnpj"
                InputProps={{
                  inputComponent: CpfCnpjTextField,
                  maxLength: 15,
                }}
              />
            </StyledGrid>
            <StyledGrid item xs={12} sm={12} md={5}>
              <StyledControlledCustomTextField
                formProps={formProps}
                fullWidth
                name="nome"
                label="Nome do cliente"
                inputProps={{ maxLength: 100 }}
              />
            </StyledGrid>
            <StyledGrid item xs={12} sm={12} md={4}>
              <StyledControlledCustomTextField
                formProps={formProps}
                fullWidth
                name="nomeAbreviado"
                label="Nome Abreviado"
                inputProps={{ maxLength: 10 }}
              />
            </StyledGrid>
            <StyledGrid item xs={12} sm={12} md={6}>
              <StyledControlledCustomTextField
                formProps={formProps}
                fullWidth
                name="tipo"
                label="Tipo"
                select
                onChange={(e) => {
                  if (e.target.value === "F") {
                    setDisableAutoComplete(false);
                  }
                  if (e.target.value === "M") {
                    formProps.clearErrors("idMatriz");
                    setDisableAutoComplete(true);
                  }
                }}
              >
                <MenuItem key={"M"} value={"M"}>
                  Matriz
                </MenuItem>
                <MenuItem key={"F"} value={"F"}>
                  Filial
                </MenuItem>
              </StyledControlledCustomTextField>
            </StyledGrid>

            <StyledGrid item xs={12} sm={12} md={6}>
              <StyledControlledCustomTextField
                formProps={formProps}
                fullWidth
                name="situacao"
                label="Situação"
                select
              >
                <MenuItem key={"A"} value={"A"}>
                  Ativo
                </MenuItem>
                <MenuItem key={"I"} value={"I"}>
                  Inativo
                </MenuItem>
              </StyledControlledCustomTextField>
            </StyledGrid>
            {!disableAutoComplete && (
              <StyledGrid item xs={12} sm={12} md={6}>
                {data && dataMatrizes.length > 0 && (
                  <CustomControlledAutoComplete
                    formProps={formProps}
                    fullWidth
                    name="idMatriz"
                    label="Matriz"
                    labelField={"nome"}
                    idField={"id"}
                    domain={dataMatrizes}
                    disabled={disableAutoComplete}
                    loading={loadingMatrizes.toString()}
                  />
                )}
              </StyledGrid>
            )}
            <StyledGrid item xs={12} sm={12} md={3}>
              <StyledControlledCustomTextField
                formProps={formProps}
                fullWidth
                name="dataHoraCriacao"
                label="Data e hora de criação"
                disabled
              ></StyledControlledCustomTextField>
            </StyledGrid>
            <StyledGrid item xs={12} sm={12} md={3}>
              <StyledControlledCustomTextField
                formProps={formProps}
                fullWidth
                name="dataHoraAtualizacao"
                label="Data e hora de atualização"
                disabled
              ></StyledControlledCustomTextField>
            </StyledGrid>
          </StyledGridContainer>
        </StyledCard>
        <StyledCard>
          <StyledCardTitle>Permissões do Usuário Final</StyledCardTitle>
          <StyledGridContainer container>
            <StyledGrid item xs={12} sm={12} md={4}>
              <StyledControlledSwitch
                formProps={formProps}
                fullWidth
                name="exibeMapaProUsuarioFinal"
                label="Habilitar Mapa para Usuário Final?"
              ></StyledControlledSwitch>
            </StyledGrid>
            <StyledGrid item xs={12} sm={12} md={4}>
              <StyledControlledSwitch
                formProps={formProps}
                fullWidth
                name="exibeOpcaoPercursoOperacaoDiaria"
                label="Habilitar Percurso do Mapa na Operação Diária?"
              ></StyledControlledSwitch>
            </StyledGrid>
            <StyledGrid item xs={12} sm={12} md={4}>
              <StyledControlledSwitch
                formProps={formProps}
                fullWidth
                name="exibeOpcaoEfetivoOperacaoDiaria"
                label="Habilitar Efetivo do Mapa na Operação Diária?"
              ></StyledControlledSwitch>
            </StyledGrid>
          </StyledGridContainer>
        </StyledCard>
        <StyledCard>
          <StyledCardTitle>Endereço</StyledCardTitle>
          <StyledGridContainer container>
            <StyledGrid item xs={12} sm={12} md={4}>
              <StyledControlledCustomTextField
                label="CEP"
                name={"cep"}
                formProps={formProps}
                onBlur={() => handleZipCodeBlurred(formProps.getValues("cep"))}
                InputProps={{
                  inputComponent: ZipCodeTextField,
                }}
              />
            </StyledGrid>
            <StyledGrid item xs={12} sm={12} md={6}>
              <StyledControlledCustomTextField
                label="Logradouro"
                name={"logradouro"}
                formProps={formProps}
                margin={"none"}
                inputProps={{ maxLength: 40 }}
              />
            </StyledGrid>
            <StyledGrid item xs={12} sm={12} md={2}>
              <StyledControlledCustomTextField
                label="Número"
                name={"numeroLogradouro"}
                formProps={formProps}
                margin={"none"}
                inputProps={{ maxLength: 7 }}
              />
            </StyledGrid>
            <StyledGrid item xs={12} sm={12} md={4}>
              <StyledControlledCustomTextField
                label="Complemento"
                name={"complemento"}
                formProps={formProps}
                margin={"none"}
                inputProps={{ maxLength: 30 }}
              />
            </StyledGrid>
            <StyledGrid item xs={12} sm={12} md={3}>
              <StyledControlledCustomTextField
                label="Bairro"
                name={"bairro"}
                formProps={formProps}
                margin={"none"}
                inputProps={{ maxLength: 40 }}
              />
            </StyledGrid>
            <StyledGrid item xs={12} sm={12} md={3}>
              <StyledControlledCustomTextField
                label="Cidade"
                name={"municipio"}
                formProps={formProps}
                margin={"none"}
                flex={3}
                inputProps={{ maxLength: 60 }}
              />
            </StyledGrid>
            <StyledGrid item xs={12} sm={12} md={2}>
              <StyledControlledCustomTextField
                label="UF"
                name={"uf"}
                formProps={formProps}
                margin={"none"}
                select
              >
                {states.map((state) => (
                  <MenuItem
                    className={"select-submenu-item"}
                    key={state}
                    value={state}
                  >
                    {state}
                  </MenuItem>
                ))}
              </StyledControlledCustomTextField>
            </StyledGrid>
          </StyledGridContainer>
        </StyledCard>
        <StyledCard>
          <StyledCardTitle>Roteiro</StyledCardTitle>
          <StyledGridContainer container>
            <StyledGrid item xs={12} sm={12} md={2}>
              <StyledControlledCustomTextField
                formProps={formProps}
                fullWidth
                name="lote"
                label="Lote"
                type={"number"}
                inputProps={{ min: 0, max: 32767 }}
              />
            </StyledGrid>
            <StyledGrid item xs={12} sm={12} md={4}>
              <StyledControlledCustomTextField
                formProps={formProps}
                fullWidth
                name="latitude"
                label="Latitude"
                inputProps={{ maxLength: 15 }}
              ></StyledControlledCustomTextField>
            </StyledGrid>
            <StyledGrid item xs={12} sm={12} md={4}>
              <StyledControlledCustomTextField
                formProps={formProps}
                fullWidth
                name="longitude"
                label="Longitude"
                inputProps={{ maxLength: 15 }}
              ></StyledControlledCustomTextField>
            </StyledGrid>
            <StyledGrid item xs={12} sm={12} md={2}>
              <StyledControlledSwitch
                onChange={handleDisableRoteiro}
                formProps={formProps}
                fullWidth
                name="roteiro"
                label="Possui Roteiro?"
              ></StyledControlledSwitch>
            </StyledGrid>
            <StyledGrid item xs={12} sm={12} md={4}>
              <StyledControlledCustomTextField
                formProps={formProps}
                fullWidth
                name="pontosRegiao"
                label="Pontos na região"
                disabled={!disableRoteiro}
                type="number"
                inputProps={{ min: 1, max: 32767 }}
              ></StyledControlledCustomTextField>
            </StyledGrid>
            <StyledGrid item xs={12} sm={12} md={4}>
              <StyledControlledCustomTextField
                formProps={formProps}
                fullWidth
                name="raioPrecisao"
                label="Raio de precisão"
                disabled={!disableRoteiro}
                type="number"
                inputProps={{ min: 1, max: 32767 }}
              ></StyledControlledCustomTextField>
            </StyledGrid>
            <StyledGrid item xs={12} sm={12} md={4}>
              <StyledControlledCustomTextField
                formProps={formProps}
                fullWidth
                name="precisao"
                label="Precisão"
                disabled={disableRoteiro}
                type="number"
                inputProps={{ min: 1, max: 32767 }}
              ></StyledControlledCustomTextField>
            </StyledGrid>
          </StyledGridContainer>
        </StyledCard>
        <StyledCard>
          <StyledCardTitle>Grupos</StyledCardTitle>
          <StyledTable>
            <TableBody>
              <StyledTableRow>
                <StyledTitleTableCell>Descrição</StyledTitleTableCell>
                <StyledTitleTableCell></StyledTitleTableCell>
              </StyledTableRow>
              {data?.grupos &&
                data?.grupos.map((item, index) => {
                  return (
                    <StyledTableRow key={item.id}>
                      <StyledTableCell>{item.descricao}</StyledTableCell>
                      <StyledButtonTableCell align="right">
                        <IconButton
                          disabled={deletingGroup}
                          onClick={(e) => {
                            setDialogTitle(
                              `Tem certeza que gostaria de deletar o grupo ${item.descricao}?`
                            );
                            setIdToAct(item.id);
                            setOpenDialog(true);
                          }}
                        >
                          <DeleteSharp />
                        </IconButton>
                      </StyledButtonTableCell>
                    </StyledTableRow>
                  );
                })}
            </TableBody>
            <DeleteDialog
              title={dialogTitle}
              open={openDialog}
              setOpen={setOpenDialog}
              deleteFunction={handleDeleteGrupo}
              id={idToAct}
            />
          </StyledTable>
        </StyledCard>
        <StyledCard>
          <StyledCardTitle>Chave de Comunicação</StyledCardTitle>
          <Grid container spacing={2}>
            {data.chaveApi && (
              <Grid item xs={12}>
                <FlexContainer
                  __justifyContent={"flex-start"}
                  __alignItems={"flex-start"}
                  __direction={"column"}
                >
                  <SpacedThinText __margin={"1rem 0 0 0"} __align={"left"}>
                    Chave da API: <b>{data.chaveApi}</b>{" "}
                    <Tooltip title={"Copiar"} arrow>
                      <IconButton
                        onClick={() => handleCopyApiKey(data.chaveApi)}
                      >
                        <CopyAll />
                      </IconButton>
                    </Tooltip>
                  </SpacedThinText>
                  <SpacedThinText __margin={"0"} __align={"left"}>
                    Segredo da API: <b>{data.segredoApi}</b>{" "}
                    <Tooltip title={"Copiar"} arrow>
                      <IconButton
                        onClick={() => handleCopyApiKey(data.segredoApi)}
                      >
                        <CopyAll />
                      </IconButton>
                    </Tooltip>
                  </SpacedThinText>
                  <SpacedThinText __margin={"9px 0 1rem"} __align={"left"}>
                    Data/hora de geração:{" "}
                    <b>
                      {formatters.dates.withHours(
                        new Date(data.dataHoraGeracaoChaveApi)
                      )}
                    </b>
                  </SpacedThinText>
                </FlexContainer>
                <Grid item xs={12}>
                  <Grid container spacing={2} justifyContent="center">
                    <Grid item>
                      <StyledVoltarButton
                        onClick={() => setRemoveKey(true)}
                        loading={submittingChaveApi}
                        loaderColor={"blue"}
                      >
                        CANCELAR CHAVE
                      </StyledVoltarButton>
                    </Grid>
                    <Grid item>
                      <StyledAtualizarButton
                        onClick={() => setConfirmation(true)}
                        loading={submittingChaveApi}
                      >
                        GERAR NOVA CHAVE
                      </StyledAtualizarButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {!data.chaveApi && (
              <Grid item xs={12}>
                <FlexContainer
                  __direction={"column"}
                  __justifyContent={"center"}
                  __alignItems={"center"}
                >
                  <SpacedThinText __margin={"1rem 0"}>
                    Este cliente <b>não</b> possui chave de comunicação ativa.
                  </SpacedThinText>
                  <StyledAtualizarButton
                    onClick={handleGenerateApiKey}
                    loading={submittingChaveApi}
                  >
                    GERAR CHAVE
                  </StyledAtualizarButton>
                </FlexContainer>
              </Grid>
            )}
            <DeleteDialog
              isConfirmacao={true}
              title={
                "Esta ação irá gerar uma nova chave e desabilitará a chave atual.\n\nDeseja continuar?"
              }
              open={openConfirmation}
              setOpen={setConfirmation}
              deleteFunction={handleGenerateApiKey}
            />
            <DeleteDialog
              title={"Remover chave de comunicação?"}
              open={openRemoveKey}
              setOpen={setRemoveKey}
              deleteFunction={handleDeleteApiKey}
            />
          </Grid>
        </StyledCard>
        <Grid justifyContent="center" container>
          <Grid item xs={12} sm={12} md={6}>
            <ButtonContainer>
              <StyledVoltarButton onClick={handleGoBack}>
                Voltar
              </StyledVoltarButton>
            </ButtonContainer>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <ButtonContainerLeft>
              <StyledAtualizarButton type={"submit"} loading={loading}>
                Editar
              </StyledAtualizarButton>
            </ButtonContainerLeft>
          </Grid>
        </Grid>
      </StyledForm>
    </BasePage>
  );
};

export default FormEditarClientePage;
