import basicFlow from "./asyncHandler";
import {
  put,
  select,
  takeEvery,
  fork,
  delay,
  cancel,
} from "redux-saga/effects";
import { actions, types } from "../reducers/mapa.actions";
import { authenticatedRequest } from "../utils/api";
import { routeWatcher } from "./rotas.saga";
import { types as routes } from "../reducers/rotas.actions";
import {
  getFiltros,
  getItems,
  getPosicao,
  getSelecionado,
} from "../selectors/mapa.selectors";
import formatters from "../utils/formatters";
import { getUsuario } from "../selectors/usuario.selectors";
import { getCurrentRoute, getPayload } from "../selectors/routes.selectors";

let atualizacaoAutomaticaHolder = null;

function* atualizacaoAutomaticaTimer() {
  const WAIT = 60;
  try {
    while (true) {
      yield delay(WAIT * 1000);
      const rotaAtual = yield select(getCurrentRoute);
      const selecionado = yield select(getSelecionado);
      if (rotaAtual === routes.MAPA && !selecionado) {
        yield put(actions.atualizacaoAutomatica.request());
      }
    }
  } catch (e) {}
}

const pesquisar = basicFlow({
  actionGenerator: actions.pesquisar,
  transform: function* () {
    const currentFilter = yield select(getFiltros);
    const usuario = yield select(getUsuario);
    const empresa = currentFilter.empresa?.id
      ? currentFilter.empresa?.id
      : !!usuario?.empresa
      ? usuario.empresa
      : currentFilter.empresa?.id;
    return {
      ...currentFilter,
      empresa,
      inicioPeriodo: formatters.dates.revert(currentFilter.inicioPeriodo),
      fimPeriodo: formatters.dates.revert(currentFilter.fimPeriodo),
      dispositivo: currentFilter.dispositivo?.id,
      grupo: currentFilter.grupo?.id,
      funcionamento: currentFilter.funcionamento.map((f) => f.value),
    };
  },
  api: (values) => {
    return authenticatedRequest({
      url: `/os`,
      method: "get",
      queryParams: values,
    });
  },
  postSuccess: function* () {
    atualizacaoAutomaticaHolder = yield fork(atualizacaoAutomaticaTimer);
  },
});
const atualizarOs = basicFlow({
  actionGenerator: actions.atualizarOs,
  api: ({ id }) => {
    return authenticatedRequest({
      url: `/os/${id}`,
      method: "get",
    });
  },
});

const atualizarSelecionarOs = basicFlow({
  actionGenerator: actions.atualizarSelecionarOs,
  api: ({ id }) => {
    return authenticatedRequest({
      url: `/os/${id}`,
      method: "get",
    });
  },
  postSuccess: function* ({ response }) {
    yield put(actions.selecionarOs(response.data));
  },
});

const proximaPagina = basicFlow({
  actionGenerator: actions.proximaPagina,
  transform: function* () {
    const currentFilter = yield select(getFiltros);
    const usuario = yield select(getUsuario);

    const empresa = currentFilter.empresa?.id ?? usuario?.empresa ?? "";

    return {
      ...currentFilter,
      empresa,
      inicioPeriodo: formatters.dates.revert(currentFilter.inicioPeriodo),
      fimPeriodo: formatters.dates.revert(currentFilter.fimPeriodo),
      dispositivo: currentFilter.dispositivo?.id,
      grupo: currentFilter.grupo?.id,
      funcionamento: currentFilter.funcionamento.map((f) => f.value),
      page: currentFilter.page + 1,
    };
  },
  api: (values) => {
    return authenticatedRequest({
      url: `/os`,
      method: "get",
      queryParams: values,
    });
  },
});

const atualizacaoAutomatica = basicFlow({
  actionGenerator: actions.atualizacaoAutomatica,
  transform: function* () {
    const currentFilter = yield select(getFiltros);
    const usuario = yield select(getUsuario);
    const items = yield select(getItems);

    const empresa = currentFilter.empresa?.id ?? usuario?.empresa ?? "";

    return {
      ...currentFilter,
      empresa,
      inicioPeriodo: formatters.dates.revert(currentFilter.inicioPeriodo),
      fimPeriodo: formatters.dates.revert(currentFilter.fimPeriodo),
      dispositivo: currentFilter.dispositivo?.id,
      grupo: currentFilter.grupo?.id,
      funcionamento: currentFilter.funcionamento.map((f) => f.value),
      page: 1,
      size: items.length,
    };
  },
  api: (values) => {
    return authenticatedRequest({
      url: `/os`,
      method: "get",
      queryParams: values,
    });
  },
});
const reiniciarPagina = basicFlow({
  actionGenerator: actions.reiniciarPagina,
  transform: function* () {
    const currentFilter = yield select(getFiltros);
    const usuario = yield select(getUsuario);

    const empresa = currentFilter.empresa?.id ?? usuario?.empresa ?? "";

    return {
      ...currentFilter,
      empresa,
      inicioPeriodo: formatters.dates.revert(currentFilter.inicioPeriodo),
      fimPeriodo: formatters.dates.revert(currentFilter.fimPeriodo),
      dispositivo: currentFilter.dispositivo?.id,
      grupo: currentFilter.grupo?.id,
      funcionamento: currentFilter.funcionamento.map((f) => f.value),
      page: 1,
    };
  },
  api: (values) => {
    return authenticatedRequest({
      url: `/os`,
      method: "get",
      queryParams: values,
    });
  },
});

const carregarEmpresas = basicFlow({
  actionGenerator: actions.carregarEmpresas,
  api: (values) => {
    return authenticatedRequest({
      url: `/cliente/dominio?cliente=${values?.cliente ?? "false"}`,
      method: "get",
    });
  },
});
const carregarDispositivos = basicFlow({
  actionGenerator: actions.carregarDispositivos,
  api: () => {
    return authenticatedRequest({
      url: `/dispositivo/dominio`,
      method: "get",
    });
  },
});
const carregarGrupos = basicFlow({
  actionGenerator: actions.carregarGrupos,
  api: ({ id }) => {
    return authenticatedRequest({
      url: `/roteiro/listar/grupos`,
      method: "post",
      body: {
        idCliente: id,
      },
    });
  },
});
const excluirPosicao = basicFlow({
  actionGenerator: actions.excluirPosicao,
  transform: ({ id }) => {
    return { id };
  },
  api: ({ id }) => {
    return authenticatedRequest({
      url: `/roteiro/delete-posicao/${id}`,
      method: "post",
    });
  },
  postSuccess: ({ original }) => {
    if (!!original.callback) {
      original.callback();
    }
  },
});
function* atualizarFiltrosWatcher() {
  yield takeEvery([types.ATUALIZAR_FILTROS], function* () {
    if (!!atualizacaoAutomaticaHolder) {
      yield cancel(atualizacaoAutomaticaHolder);
      atualizacaoAutomaticaHolder = null;
    }
    yield put(actions.pesquisar.request());
  });
}
function* fecharMovimentoCheckpointWatcher() {
  yield takeEvery([types.FECHAR_MOVIMENTO_CHECKPOINT], function* () {
    const { origem, target } = yield select(getPosicao);
    target.setPosition(origem.latLng);
    yield put(actions.perguntarMovimento(null, null, null));
  });
}
const moverCheckpoint = basicFlow({
  actionGenerator: actions.moverCheckpoint,
  transform: function* () {
    const { ponto, evento } = yield select(getPosicao);
    return {
      id: ponto.id,
      lat: evento.latLng.lat(),
      lng: evento.latLng.lng(),
    };
  },
  api: (values) => {
    return authenticatedRequest({
      url: `/mover-checkpoint`,
      method: "post",
      body: values,
    });
  },
  postSuccess: function* () {
    yield put(actions.comecarMovimento(null));
    yield put(actions.perguntarMovimento(null, null, null));
  },
});
function* mapRouteWatcher() {
  yield routeWatcher(routes.MAPA, function* () {
    const currentFilter = yield select(getFiltros);
    const usuario = yield select(getUsuario);

    yield put(actions.carregarDispositivos.request());
    if (!usuario?.empresa) {
      yield put(actions.carregarEmpresas.request());
    } else {
      yield put(
        actions.carregarEmpresas.request({ cliente: usuario?.empresa })
      );
      yield put(actions.carregarGrupos.request({ id: usuario.empresa }));
    }

    if (currentFilter.inicioPeriodo === null) {
      yield put(
        actions.atualizarFiltros({
          inicioPeriodo: formatters.dates.short(new Date()),
          fimPeriodo: formatters.dates.short(new Date()),
        })
      );
    } else {
      actions.atualizarFiltros({
        page: 1,
      });
    }
  });
}
function* mapOsRouteWatcher() {
  yield routeWatcher(routes.MAPA_OS, function* (action) {
    const currentFilter = yield select(getFiltros);
    const usuario = yield select(getUsuario);
    const params = yield select(getPayload);

    yield put(actions.atualizarSelecionarOs.request(params));

    yield put(actions.carregarDispositivos.request());
    if (!usuario?.empresa) {
      yield put(actions.carregarEmpresas.request());
    } else {
      yield put(
        actions.carregarEmpresas.request({ cliente: usuario?.empresa })
      );
      yield put(actions.carregarGrupos.request({ id: usuario.empresa }));
    }

    if (currentFilter.inicioPeriodo === null) {
      yield put(
        actions.atualizarFiltros({
          inicioPeriodo: formatters.dates.short(new Date()),
          fimPeriodo: formatters.dates.short(new Date()),
        })
      );
    } else {
      actions.atualizarFiltros({
        page: 1,
      });
    }
  });
}

const downloadRoteiro = basicFlow({
  actionGenerator: actions.downloadRoteiro,
  api: (id) => {
    return authenticatedRequest({
      url: `/roteiro/gerarRoteiroCsv/${id}`,
      method: "get",
    });
  },
});

const downloadOs = basicFlow({
  actionGenerator: actions.downloadOs,
  transform: function* () {
    const currentFilter = yield select(getFiltros);
    const usuario = yield select(getUsuario);

    const empresa = currentFilter.empresa?.id ?? usuario?.empresa ?? "";

    return {
      ...currentFilter,
      empresa,
      inicioPeriodo: formatters.dates.revert(currentFilter.inicioPeriodo),
      fimPeriodo: formatters.dates.revert(currentFilter.fimPeriodo),
      dispositivo: currentFilter.dispositivo?.id,
      grupo: currentFilter.grupo?.id,
      funcionamento: currentFilter.funcionamento.map((f) => f.value),
    };
  },
  api: (values) => {
    return authenticatedRequest({
      url: `/relatorioOsCsv`,
      method: "get",
      queryParams: values,
    });
  },
});

export const sagas = [
  pesquisar.watcher(),
  atualizarOs.watcher(),
  proximaPagina.watcher(),
  carregarEmpresas.watcher(),
  carregarDispositivos.watcher(),
  carregarGrupos.watcher(),
  excluirPosicao.watcher(),
  reiniciarPagina.watcher(),
  atualizarFiltrosWatcher(),
  atualizacaoAutomatica.watcher(),
  downloadRoteiro.watcher(),
  downloadOs.watcher(),
  atualizarSelecionarOs.watcher(),
  moverCheckpoint.watcher(),
  mapRouteWatcher(),
  mapOsRouteWatcher(),
  fecharMovimentoCheckpointWatcher(),
];
