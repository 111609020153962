import { createSyncAction } from "../utils/actionCreators";
import { NOT_FOUND } from "redux-first-router";

export const types = {
  NOT_FOUND: NOT_FOUND,
  OPEN_WINDOW: "@@route/OPEN_WINDOW",
  LOGIN: "@@route/login",
  ESQUECI_SENHA: "@@route/esqueci-minha-senha",
  TROCAR_SENHA: "@@route/trocar-senha",
  HOME_PAGE: "@@route/home-page",
  MAPA: "@@route/mapa",
  MAPA_OS: "@@route/mapa-os",
  OPERACAO_DIARIA: "@@route/operacao-diaria",
  CADASTRO_USUARIO: "@@route/cadastro-usuario",
  EDITAR_USUARIO: "@@route/editar-usuario",
  CRIAR_USUARIO: "@@route/criar-usuario",
  CADASTRO_DISPOSITIVO: "@@route/cadastro-dispositivo",
  EDITAR_DISPOSITIVO: "@@route/editar-dispositivo",
  CRIAR_DISPOSITIVO: "@@route/criar-dispositivo",
  CADASTRO_CLIENTE: "@@route/cadastro-cliente",
  EDITAR_CLIENTE: "@@route/editar-cliente",
  VIEW_CLIENTE: "@@route/view-cliente",
  CRIAR_CLIENTE: "@@route/criar-cliente",
  CADASTRO_INTEGRACAO: "@@route/cadastro-integracao",
  EDITAR_INTEGRACAO: "@@route/editar-integracao",
  CRIAR_INTEGRACAO: "@@route/criar-integracao",
  CADASTRO_ROTEIRO: "@@route/cadastro-roteiro",
  EDITAR_ROTEIRO: "@@route/editar-roteiro",
  CRIAR_ROTEIRO: "@@route/criar-roteiro",
  CARGA_ROTEIRO: "@@route/carga-roteiro",
  CARGA_ARQUIVO: "@@route/carga-arquivo",
  GERACAO_OS: "@@route/geracao-os",
  CADASTRO_FERIADO: "@@route/cadastro-feriado",
  EDITAR_FERIADO: "@@route/editar-feriado",
  CRIAR_FERIADO: "@@route/criar-feriado",
  REPROCESSAMENTO_OS: "@@route/reprocessamento-os",
  CADASTRO_MAPA_MENSAL: "@@route/cadastro-mapa-mensal",
  EDITAR_MAPA_MENSAL: "@@route/editar-mapa-mensal",
  CRIAR_MAPA_MENSAL: "@@route/criar-mapa-mensal",
  CARGA_TROCAR_DISPOSITIVO: "@@route/carga-trocar-dispositivo",
  CARGA_DISPOSITIVO: "@@route/carga-dispositivo",
  BATERIAS: "@@route/baterias",
  ERRO_OS: "@@route/roteiro-inconsistencia",
  CADASTRO_ALERTA: "@@route/cadastro-alerta",
  EDITAR_ALERTA: "@@route/editar-alerta",
  CRIAR_ALERTA: "@@route/criar-alerta",
  PAINEL_ALERTAS: "@@route/painel-alertas",
  RELATORIO_PENDENTES: "@@route/relatorio-pendentes",
};

export const actions = {
  redirectTo: (route, params = {}) => createSyncAction(route, params),
  openNewWindowWith: (route, params = {}) =>
    createSyncAction(types.OPEN_WINDOW, {
      route,
      params,
    }),
  rejectTo: (route, params = {}) =>
    createSyncAction(route, params, {
      meta: {
        location: {
          kind: "redirect",
        },
      },
    }),
};
