import React, { useEffect } from "react";
import RelatorioPontosPendentesPage from "./RelatorioPontosPendentesPage";
import { useDispatch, useSelector } from "react-redux";
import {
  getDominioDispositivos,
  getDominioEmpresas,
  isProcessando,
} from "../../selectors/relatorioPontosPendentes.selectors";
import useCompleteForm from "../../hooks/useCompleteForm";
import validators from "../../utils/validators";
import formatters from "../../utils/formatters";
import { actions } from "../../reducers/relatorioPontosPendentes.actions";
import { getUsuario } from "../../selectors/usuario.selectors";

function RelatorioPontosPendentesPageConnected() {
  const { data: dominioDispositivos } = useSelector(getDominioDispositivos);
  const { data: dominioEmpresas } = useSelector(getDominioEmpresas);
  const processing = useSelector(isProcessando);
  const { empresa } = useSelector(getUsuario);

  const dispatch = useDispatch();

  const [formProps, handleSubmit] = useCompleteForm({
    rules: (f) => ({
      dataInicio: validators.date({
        required: true,
        custom: {
          small: (value) => {
            const fim = f.getValues("dataFim");
            const r1 = formatters.dates.revert(value);
            const r2 = formatters.dates.revert(fim);

            return (
              r1.localeCompare(r2) <= 0 ||
              "Valor não deve ser maior que Data Fim"
            );
          },
        },
      }),
      dataFim: validators.date({
        required: true,
        custom: {
          large: (value) => {
            const inicio = f.getValues("dataInicio");
            const r1 = formatters.dates.revert(value);
            const r2 = formatters.dates.revert(inicio);

            return (
              r1.localeCompare(r2) >= 0 ||
              "Valor não deve ser menor que Data Início"
            );
          },
        },
      }),
      empresa: validators.object({ required: true }),
      limite: validators.number({
        required: true,
        custom: {
          _100: (value) =>
            !value || value <= 100 || "Valor não pode ser maior do que 100",
        },
      }),
    }),
    initialValues: () => ({
      dataInicio: "",
      dataFim: "",
      empresa: dominioEmpresas.find((el) => el.id === empresa) ?? null,
      roteiro: "",
      dispositivo: null,
      limite: "",
    }),
    handleSubmit: (values) => {
      dispatch(actions.processar.request(values));
    },
  });

  const { setValue } = formProps;

  useEffect(() => {
    if (dominioEmpresas.find((el) => el.id === empresa)) {
      setValue(
        "empresa",
        dominioEmpresas.find((el) => el.id === empresa)
      );
    }
  }, [empresa, dominioEmpresas, setValue]);

  return (
    <RelatorioPontosPendentesPage
      dominioDispositivos={dominioDispositivos}
      dominioEmpresas={dominioEmpresas}
      processing={processing}
      formProps={formProps}
      handleSubmit={handleSubmit}
      showEmpresa={!empresa}
    />
  );
}

export default RelatorioPontosPendentesPageConnected;
