import { combineReducers } from "redux";
import { reducer as routesReducer } from "../routes";
import { default as autenticacao } from "./autenticacao.reducer";
import { default as mapa } from "./mapa.reducer";
import { default as diario } from "./diario.reducer";
import { default as usuario } from "./usuario.reducer";
import { default as usuarios } from "./cadastro/usuarios.reducer";
import { default as dispositivos } from "./cadastro/dispositivos.reducer";
import { default as clientes } from "./cadastro/clientes.reducer";
import { default as integracoes } from "./cadastro/integracoes.reducer";
import { default as feriados } from "./cadastro/feriados.reducer";
import { default as roteiros } from "./cadastro/roteiros.reducer";
import { default as geracao } from "./configuracao/geracao.reducer";
import { default as reprocessamento } from "./configuracao/reprocessamento.reducer";
import { default as mapasMensais } from "./cadastro/mapasMensais.reducer";
import { default as mapaExecucao } from "./mapaExecucao.reducer";
import { default as baterias } from "./baterias.reducer";
import { default as roteiroInconsistencia } from "./roteiroInconsistencia.reducer";
import { default as alertas } from "./cadastro/alertas.reducer";
import { default as painelAlerta } from "./painelAlerta.reducer";
import { default as relatorioPontosPendentes } from "./relatorioPontosPendentes.reducer";

export default combineReducers({
  location: routesReducer,
  mapa,
  diario,
  autenticacao,
  usuario,
  usuarios,
  dispositivos,
  clientes,
  integracoes,
  roteiros,
  feriados,
  "geracao-os": geracao,
  "reprocessamento-os": reprocessamento,
  mapasMensais,
  mapaExecucao,
  baterias,
  roteiroInconsistencia,
  alertas,
  painelAlerta,
  "relatorio-pontos-pendentes": relatorioPontosPendentes,
});
