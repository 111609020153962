import basicFlow from "./asyncHandler";
import { authenticatedRequest } from "../utils/api";
import { routeWatcher } from "./rotas.saga";
import { types as routes } from "../reducers/rotas.actions";
import { actions } from "../reducers/relatorioPontosPendentes.actions";
import { put } from "redux-saga/effects";
import { toast } from "react-toastify";
import formatters from "../utils/formatters";
import { saveAs } from "file-saver";

function* relatorioPontosPendentesRouteWatcher() {
  yield routeWatcher(routes.RELATORIO_PENDENTES, function* () {
    yield put(actions.carregarEmpresas.request());
    yield put(actions.carregarDispositivos.request());
  });
}

const carregarEmpresas = basicFlow({
  actionGenerator: actions.carregarEmpresas,
  api: (values) => {
    return authenticatedRequest({
      url: `/cliente/dominio?cliente=${values?.cliente ?? "false"}`,
      method: "get",
    });
  },
});
const carregarDispositivos = basicFlow({
  actionGenerator: actions.carregarDispositivos,
  api: () => {
    return authenticatedRequest({
      url: `/dispositivo/dominio`,
      method: "get",
    });
  },
});

const processar = basicFlow({
  actionGenerator: actions.processar,
  transform: (payload) => {
    return {
      ...payload,
      dataInicio: formatters.dates.revert(payload.dataInicio),
      dataFim: formatters.dates.revert(payload.dataFim),
      empresa: payload.empresa.id,
    };
  },
  api: (values) => {
    return authenticatedRequest({
      url: `/relatorios/pontos-pendentes`,
      isResourceService: true,
      method: "post",
      responseType: "arraybuffer",
      body: { ...values },
    });
  },
  postSuccess: ({ response, original }) => {
    downloadPlanilha(response, original);
  },
});

const downloadPlanilha = (response) => {
  var bytes = new Uint8Array(response.data);

  var blob = new Blob([bytes], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  saveAs(
    blob,
    `relatorioPontosPendentes_${formatters.dates.withHoursNoSpace(
      new Date()
    )}.xlsx`
  );
  toast.info("Arquivo gerado com sucesso");
};

export const sagas = [
  relatorioPontosPendentesRouteWatcher(),
  carregarEmpresas.watcher(),
  carregarDispositivos.watcher(),
  processar.watcher(),
];
