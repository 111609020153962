import { actions } from "./relatorioPontosPendentes.actions";

const initialState = {
  processing: false,
  dominios: {
    empresas: {
      loading: true,
      data: [],
    },
    dispositivos: {
      loading: true,
      data: [],
    },
  },
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.processar.REQUEST:
    case actions.processar.SUCCESS:
    case actions.processar.FAILURE:
      return {
        ...state,
        processing: action.type === actions.processar.REQUEST,
      };
    case actions.carregarEmpresas.REQUEST:
    case actions.carregarEmpresas.SUCCESS:
    case actions.carregarEmpresas.FAILURE:
      return {
        ...state,
        dominios: {
          ...state.dominios,
          empresas: {
            ...state.dominios.empresas,
            loading: action.type === actions.carregarEmpresas.REQUEST,
            data:
              action.type === actions.carregarEmpresas.SUCCESS
                ? [...action.payload.response.data]
                : state.dominios.empresas.data,
          },
        },
      };
    case actions.carregarDispositivos.REQUEST:
    case actions.carregarDispositivos.SUCCESS:
    case actions.carregarDispositivos.FAILURE:
      return {
        ...state,
        dominios: {
          ...state.dominios,
          dispositivos: {
            ...state.dominios.dispositivos,
            loading: action.type === actions.carregarDispositivos.REQUEST,
            data:
              action.type === actions.carregarDispositivos.SUCCESS
                ? [...action.payload.response.data]
                : state.dominios.dispositivos.data,
          },
        },
      };

    default:
      return state;
  }
};

export default reducer;
