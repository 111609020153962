import { createAsyncAction, createSyncAction } from "../utils/actionCreators";

export const types = {
  DOMINIO_EMPRESAS: "@@relatorio-pontos-pendentes/DOMINIO_EMPRESAS",
  DOMINIO_DISPOSITIVOS: "@@relatorio-pontos-pendentes/DOMINIO_DISPOSITIVOS",
  PROCESSAR: "@@relatorio-pontos-pendentes/PROCESSAR",
};

export const actions = {
  carregarEmpresas: createAsyncAction(types.DOMINIO_EMPRESAS),
  carregarDispositivos: createAsyncAction(types.DOMINIO_DISPOSITIVOS),
  processar: createAsyncAction(types.PROCESSAR),
};
